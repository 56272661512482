<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#inox-gradient-4);
      }

      .cls-8 {
        fill: url(#inox-gradient-5);
      }
    </svg:style>

    <linearGradient
      id="handle-gradient"
      :x1="doorLeftWidth1 + 11.16"
      :y1="doorTopHeight1 + 148.47"
      :x2="doorLeftWidth1 + 16.74"
      :y2="doorTopHeight1 + 148.47"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#97999d"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a7aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth1 + 21.62"
      :y1="doorTopHeight1 + 105.71"
      :x2="doorLeftWidth1 + 117.91"
      :y2="doorTopHeight1 + 105.71"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-2"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 21.62"
      :y1="doorTopHeight1 + 190.84"
      :x2="doorLeftWidth1 + 117.91"
      :y2="doorTopHeight1 + 190.84"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-3"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 - 5648.16"
      :y1="doorTopHeight1 + 8946.52"
      :x2="doorLeftWidth1 - 5565.05"
      :y2="doorTopHeight1 + 8946.52"
      gradientTransform="translate(9045.34 5669.75) rotate(90)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-4"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 - 5477.9"
      :y1="doorTopHeight1 + 8946.52"
      :x2="doorLeftWidth1 - 5394.78"
      :y2="doorTopHeight1 + 8946.52"
      gradientTransform="translate(9045.34 5669.75) rotate(90)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-5"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 - 5563.05"
      :y1="doorTopHeight1 + 9004.31"
      :x2="doorLeftWidth1 - 5479.92"
      :y2="doorTopHeight1 + 9004.31"
      gradientTransform="translate(9045.34 5669.75) rotate(90)"
      xlink:href="#handle-gradient"/>

    <!-- top horizontal -->
    <rect
      v-if="showInox"
      id="inox"
      class="cls-4"
      :x="inox1X"
      :y="doorTopHeight1 + 104.7"
      :width="inoxWidth"
      height="2.02"/>
    <!-- bottom horizontal -->
    <rect
      v-if="showInox"
      id="inox-2"
      data-name="inox"
      class="cls-5"
      :x="inox1X"
      :y="doorTopHeight1 + 189.83"
      :width="inoxWidth"
      height="2.02"/>
    <!-- top vertical  -->
    <rect
      v-if="showInox"
      id="inox-3"
      data-name="inox"
      class="cls-6"
      :x="doorLeftWidth1 + 97.81"
      :y="topVerticalY"
      width="2.02"
      :height="topVerticalHeight"/>
    <!-- bottom vertical  -->
    <rect
      v-if="showInox"
      id="inox-4"
      data-name="inox"
      class="cls-7"
      :x="doorLeftWidth1 + 97.81"
      :y="doorTopHeight1 + 191.85"
      width="2.02"
      :height="bottomVerticalHeight"/>
    <!-- middle vertical -->
    <rect
      v-if="showInox"  
      id="inox-5"
      data-name="inox"
      class="cls-8"
      :x="doorLeftWidth1 + 40.02"
      :y="doorTopHeight1 + 106.7"
      width="2.02"
      height="83.13"/>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inox2Left: 22,
      leaf2Left: 7,
      inoxOffset: 22.18,
      leafOffset: 6.4,
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    },
    topVerticalY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset:
        this.doorTopHeight + this.leafOffset
    },
    topVerticalHeight() {
      return this.showBg ?
        this.doorHeight - this.inoxOffset * 2 - (this.doorTopHeight1 + 172):
        this.doorHeight - this.leafOffset * 2 - (this.doorTopHeight1 + 189)
    },
    bottomVerticalHeight() {
      return this.showBg ?
        this.doorHeight - this.inoxOffset * 2 - (this.doorTopHeight1 + 170):
        this.doorHeight - this.leafOffset * 2 - (this.doorTopHeight1 + 185)
    },
    inox1X() {
      return this.showBg ?
        this.doorLeftWidth + this.inox2Left :
        this.doorLeftWidth + this.leaf2Left
    },
    inoxWidth() {
      return this.showBg ?
        this.doorWidth - this.inox2Left * 2 :
        this.doorWidth - this.leaf2Left * 2
    },
  }
}
</script>
